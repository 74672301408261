<template>
  <CompetitionForm v-bind="props" />
</template>

<script>
import CompetitionForm from "@/components/forms/CompetitionForm.vue";
export default {
  components: { CompetitionForm },
  data() {
    return {
      props: {
        title: "Yarışma Oluştur",
        submit: (form) => {
          this.$store
            .dispatch("saveCompetition", form)
            .then(() => this.$router.push("/competitions"));
        },
        form: { location: "", kind: "", category: "", branch:"" },
      },
    };
  },
};
</script>

<style scoped>
</style>